<template>
    <section class="cta-block-container">
        <div
            :class="[ 'cta-block', {
                'cta-block--usps': usps,
                'cta-block--input': input.show,
            }]"
        >
            <div class="cta-block__inner">
                <div class="cta-block__content">
                    <div class="cta-block__content-top">
                        <div
                            v-if="heading"
                            :class="[
                                'cta-block__heading',
                                typographyUtilityClasses(typography.heading),
                            ]"
                        >
                            {{ heading }}
                        </div>

                        <div
                            v-if="subheading"
                            :class="[
                                'cta-block__subheading',
                                typographyUtilityClasses(typography.subheading),
                            ]"
                        >
                            {{ subheading }}
                        </div>
                    </div>

                    <div class="cta-block__form">
                        <DsAtomInput
                            v-if="input.show"
                            id="input"
                            name="input"
                            type="text"
                            :label="input.label"
                            :placeholder="input.placeholder"
                            :mask="input.mask"
                            :validation="input.validation"
                            :value="inputValue"
                            :is-valid="valid"
                            :error="input.errorMessage"
                            :icon="input.icon"
                            :prefix="input.prefix"
                            :suffix="input.suffix"
                            @update-value="updateValue"
                            @update-validation="updateValidation"
                        />

                        <div class="cta-block__button-container">
                            <button
                                class="cta-block__button"
                                @click="buttonClick"
                            >
                                <span
                                    class="cta-block__button-label"
                                    :class="typographyUtilityClasses(typography.buttonText)"
                                >
                                    {{ button.text }}

                                    <FontAwesomeIcon
                                        v-if="buttonIcon"
                                        :icon="buttonIcon"
                                    />
                                </span>
                            </button>

                            <WatsonAtomDynamicText
                                v-if="tinyText"
                                class="cta-block__tiny-text"
                                :text="tinyText"
                                :typography="typography.tinyText"
                            />
                        </div>
                    </div>
                </div>

                <div
                    v-if="usps"
                    class="cta-block__usps"
                >
                    <WatsonOrganismContentColumnCheckmarks
                        :content="{}"
                        :items="usps.items"
                        column-count="1"
                        :turn-on-background="false"
                        :background="null"
                        :icon-color="usps.iconColor"
                        :text-color="usps.textColor"
                        :typography="usps.typography"
                        media-position="next-to-text"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import { validateValue } from 'design-system-component-library';
import { DsAtomInput } from 'design-system-component-library/marketplaces';

import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';
import WatsonOrganismContentColumnCheckmarks from '@organisms/content-column-checkmarks/watson-organism-content-column-checkmarks.vue';
import { typographyUtilityClasses } from '@utils/typography';
import { handleClickAction } from '@utils/click-action';

const props = defineProps({
    content: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    heading: {
        type: String,
        required: true,
    },
    subheading: {
        type: String,
        required: true,
    },
    tinyText: {
        type: String,
        required: true,
    },
    input: {
        type: Object,
        required: true,
    },
    button: {
        type: Object,
        required: true,
    },
    usps: {
        type: Object,
        required: false,
        default: null,
    },
    style: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    typography: {
        type: Object,
        required: true,
    },
    clickAction: {
        type: Object,
        required: true,
    },
});

const inputValue = ref('');
const valid = ref(true);

const buttonClick = () => {
    if (props.input.show) {
        if (!validateValue(inputValue.value, props.input.validation)) {
            valid.value = false;
            return;
        }
    }

    handleClickAction(props.clickAction, inputValue.value);
};

const updateValue = value => {
    inputValue.value = value;
};

const updateValidation = value => {
    valid.value = value;
};

const buttonIcon = {
    'arrow-right': faArrowRight,
}[props.button.icon];
</script>

<style lang="scss">
@use 'watson-theme-cta-block';
</style>

<style lang="scss" scoped>
@layer theme {
    .cta-block {
        --background-color-one: v-bind('props.style.backgroundColorOne');
        --background-color-two: v-bind('props.style.backgroundColorTwo');
        --heading-color: v-bind('props.style.headingColor');
        --subheading-color: v-bind('props.style.subheadingColor');
        --tiny-text-color: v-bind('props.style.tinyTextColor');

        --input-background: v-bind('props.style.input.background');
        --input-text-color: v-bind('props.style.input.textColor');
        --input-border-color: v-bind('props.style.input.borderColor');
        --input-prefix-suffix-background: v-bind('props.style.input.prefixSuffixBackground');

        --button-background: v-bind('props.style.button.background');
        --button-background-hover: v-bind('props.style.button.backgroundHover');
        --button-background-active: v-bind('props.style.button.backgroundActive');
        --button-text-color: v-bind('props.style.button.textColor');
    }
}
</style>
